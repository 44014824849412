<template>
  <div class="wake-add-edit">
    <!-- Drawer抽屉 -->
    <Drawer :title="title" v-model="visible" width="720" on-close="handleClose()" :mask-closable="false">
      <Form ref="form" :model="form" :label-width="120" :rules="formValidate" label-position="left">
        <FormItem label="标题" prop="wakeTitle">
          <Input v-model="form.wakeTitle" :maxlength=25 clearable />
        </FormItem>
        <FormItem label="提醒内容" prop="wakeDesc">
          <Input type="textarea" v-model="form.wakeDesc" :maxlength=100 :rows=3 clearable />
        </FormItem>
        <FormItem label="提醒类型" prop="wakeType">
          <Select v-model="form.wakeType" >
            <Option v-for="(item, i) in this.$store.state.dict.wake_type" :key="i" :value="item.value">{{item.title}}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="提醒时间" prop="wakeTime2" v-if="form.wakeType==0">
          <Date-picker type="datetime" :editable="false" :value="form.wakeTime2" format="yyyy-MM-dd HH:mm" clearable
            style="width:200px" @on-change="form.wakeTime2 = $event"></Date-picker>
        </FormItem>
        <FormItem label="提醒时间" prop="wakeTime2" v-if="form.wakeType==1">
          <Time-picker type="datetime" :editable="false" :value="form.wakeTime2" format="HH:mm" clearable
            style="width:200px" @on-change="form.wakeTime2 = $event"></Time-picker>
        </FormItem>
        <FormItem label="提醒时间" prop="wakeTime1" v-if="form.wakeType==2">
          <Row style="width:450px">
            <i-col span="11">
              <Select v-model="form.wakeTime1" style="width:200px">
                <Option v-for="(item, i) in formExt.listWeek" :key="i" :value="item.value">{{ item.label }}</Option>
              </Select>
            </i-col>
            <i-col span="2" style="text-align: center">-</i-col>
            <i-col span="11">
              <FormItem prop="wakeTime2">
                <Time-picker type="datetime" :editable="false" :value="form.wakeTime2" format="HH:mm" clearable
                  style="width:200px" @on-change="form.wakeTime2 = $event"></Time-picker>
              </FormItem>
            </i-col>
          </Row>
        </FormItem>
        <FormItem label="提醒时间" prop="wakeTime1" v-if="form.wakeType==3">
          <Row style="width:450px">
            <i-col span="11">
              <Select v-model="form.wakeTime1" style="width:200px" @on-change="changeWakeTime">
                <Option v-for="(item, i) in formExt.listMonth" :key="i" :value="item.value">{{ item.label }}</Option>
              </Select>
            </i-col>
            <i-col span="2" style="text-align: center">-</i-col>
            <i-col span="11">
              <FormItem prop="wakeTime2">
                <Time-picker type="datetime" :editable="false" :value="form.wakeTime2" format="HH:mm" clearable
                  style="width:200px" @on-change="form.wakeTime2 = $event"></Time-picker>
              </FormItem>
            </i-col>
          </Row>
        </FormItem>
        <FormItem label="提醒结束时间" v-if="form.wakeType!=0">
          <Date-picker type="datetime" :editable="false" :value="form.endTime" format="yyyy-MM-dd HH:mm" clearable
            style="width:200px" @on-change="form.endTime = $event"></Date-picker>
        </FormItem>
        <FormItem label="备注" prop="remark">
          <Input type="textarea" v-model="form.remark" :maxlength=20 :rows=1 clearable />
        </FormItem>
        <FormItem label="提醒状态" prop="sendStatus" v-if="this.addEditFlag==2">
          {{this.formExt.sendStatus2}}
        </FormItem>
        <FormItem label="提醒发送时间" prop="sendTime" v-if="this.addEditFlag==2">
          {{form.sendTime}}
        </FormItem>
        <Form-item class="br">
          <Button @click="handleSubmit" :loading="submitLoading" v-if="this.data.fromFlag==1 || this.addEditFlag==1">保存</Button>
          <Button @click="handleClose">关闭</Button>
        </Form-item>
      </Form>
    </Drawer>
  </div>
</template>

<script>
  // 根据你的实际请求api.js位置路径修改
  import {
    addWake,
    editWake
  } from "@/api/busi/wake-manage/wake";
  export default {
    name: "wakeAddEdit",
    components: {},
    props: {
      data: Object,
      addEditFlag: String,
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        submitLoading: false, // 表单提交状态
        title: "提醒设置",
        visible: this.value,
        form: { // 添加或编辑表单对象初始化数据
          wakeTitle: "",
          wakeDesc: "",
          wakeType: 0,
          wakeTime1: "",
          wakeTime2: "",
          caseId: "",
          caseExt: "",
          sendStatus: 0,
          sendTime: "",
          isEnd: 0,
          endTime: "",
          remark: "",
        },
        formExt: {
          sendStatus2: "",
          listWeek: [],
          listMonth: [],
        },
        // 表单验证规则
        formValidate: {
          wakeTitle: [{
            required: true,
            message: "不能为空",
            trigger: "blur"
          }],
          wakeDesc: [{
            required: true,
            message: "不能为空",
            trigger: "blur",
            pattern: /.+/
          }],
          wakeTime1: [{
            required: true,
            message: "不能为空",
            trigger: "blur",
            pattern: /.+/
          }],
          wakeTime2: [{
            required: true,
            message: "不能为空",
            trigger: "blur",
            pattern: /.+/
          }],
          wakeType: [{
            required: true,
            message: "不能为空",
            trigger: "blur"
          }],
        }
      };
    },
    methods: {
      init() {
        // 编辑情况下
        if (this.addEditFlag == "2") {
          this.form = this.data;
          if (this.form.sendStatus == 0) {
            this.formExt.sendStatus2 = "未提醒";
          }else if (this.form.sendStatus == 1) {
            this.formExt.sendStatus2 = "已成功提醒";
          }else if (this.form.sendStatus == -1) {
            this.formExt.sendStatus2 = "提醒失败，未绑定公众号";
          }else{
            this.formExt.sendStatus2 = "提醒失败，原因未知";
          }
        }else{
          delete this.form.id;
          this.form.wakeTitle="";
          this.form.wakeDesc="";
          this.form.wakeType=0;
          this.form.wakeTime1="";
          this.form.wakeTime2="";
          this.form.caseId="";
          this.form.caseExt="";
          this.form.sendStatus=0;
          this.form.sendTime="";
          this.form.isEnd=0;
          this.form.endTime="";
          this.form.remark="";
        }

        // 初始化月下拉框
        if (this.formExt.listMonth.length == 0) {
          for (var i = 1; i <= 31; i++) {
            var json = {
              "value": String(i),
              "label": i + "号"
            };
            this.formExt.listMonth.push(json);
          }
          this.formExt.listMonth.push({
            "value": String(32),
            "label": "月末"
          });
        }
        // 初始化周下拉框
        if (this.formExt.listWeek.length == 0) {
          this.formExt.listWeek.push({
            value: "2",
            label: "周一"
          });
          this.formExt.listWeek.push({
            value: "3",
            label: "周二"
          });
          this.formExt.listWeek.push({
            value: "4",
            label: "周三"
          });
          this.formExt.listWeek.push({
            value: "5",
            label: "周四"
          });
          this.formExt.listWeek.push({
            value: "6",
            label: "周五"
          });
          this.formExt.listWeek.push({
            value: "7",
            label: "周六"
          });
          this.formExt.listWeek.push({
            value: "1",
            label: "周日"
          });
        }

      },
      setCurrentValue(value) {
        if (value === this.visible) {
          this.$refs.form.resetFields();
         return;
        }
        this.init();
        this.visible = value;
      },
      handleReset() {
        this.$refs.form.resetFields();
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            if (this.addEditFlag == "1") {
              addWake(this.form).then(res => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.$emit("on-submit", true);
                  this.visible = false;
                }
              });
            }else{
              editWake(this.form).then(res => {
                this.submitLoading = false;
                if (res.success) {
                  this.$Message.success("操作成功");
                  this.$emit("on-submit", true);
                  this.visible = false;
                }
              });
            }
          }
        });
      },
      handleClose(){
        this.visible = false;
      }

    },
    watch: {
      value(val) {
        this.setCurrentValue(val);
      },
      visible(value) {
        this.$emit("input", value);
      },
    },
    mounted() {
      this.init();
    }
  };
</script>

<style lang="less">
  // @import "../../../styles/table-common.less";
  .drawer-content {
    overflow: auto;
  }

  .drawer-content::-webkit-scrollbar {
    display: none;
  }

  .user-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .info-title {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      display: block;
      margin-right: 16px;
    }
  }

  .info-header {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    margin-bottom: 16px;
  }
</style>