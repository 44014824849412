// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 分页获取数据
export const getWakeList = (params) => {
    return postRequest('/busi/wakeView/getByCondition', params)
}

// 添加
export const addWake = (params) => {
    return postRequest('/busi/wake/insert', params)
}

// 编辑
export const editWake = (params) => {
    return postRequest('/busi/wake/update', params)
}

// 删除当前表
export const deleteWake = (params) => {
    return postRequest('/busi/wake/delByIds', params)
}

// 删除历史表
export const deleteWakeHis = (params) => {
    return postRequest('/busi/wakeHis/delByIds', params)
}